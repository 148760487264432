body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button:disabled {
  opacity: 0.7;
  pointer-events: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.instrunctions-color-text {
  color: #08466f !important;
}

.checkBox-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.checkBox-row {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: start;
  gap: 10px;
}

.no-scroll::-webkit-scrollbar {
  display: none;
}
